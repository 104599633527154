<template>
  <div class="OCT">
    <h3 style="padding: 30px 0;">欧堡检查</h3>
    <UPfile :type="'Oubao'"></UPfile>
    <div class="bz">
      <p>备注：</p>
      <el-input
          type="textarea"
          :autosize="{ minRows: 4, maxRows: 6}"
          placeholder="请输入内容"
          v-model="postData.S_OP_Json.CL_RE_Oubao_Remark">
      </el-input>
    </div>
  </div>
</template>

<script>
export default {
  name: "aubergInspection",
  data() {
    return {
      v1: '',
      postData: {
        S_OP_Type: 'CL_RE_Oubao',
        S_OP_Model: 'CL_RE_Oubao180726',
        S_OP_ChooseId: this.$store.state.physicianVisits.xzConsulting,
        S_OP_CustomerId:  this.$store.state.users.CsUser.CustomerId,
        S_OP_ExpertId: this.$store.state.users.employees.ExpertId,
        S_OP_Json: {
          CL_RE_Oubao_ChooseId : this.$store.state.physicianVisits.xzConsulting,
          CL_RE_Oubao_Remark: ""
        }
      }
    }
  },
  created() {

    this._http.post(this._getUrl('PS_all_get'), {
      "token": {
        "TimeSpan": 1566827733,
        "sToken": "768DCAFFF869CD61372790C2738012EC"
      },
      SearchCondition: {
        S_OP_Type: 'CL_RE_Oubao',
        ChooseId: this.$store.state.physicianVisits.xzConsulting
      }
    }).then(res => {
      // console.log(res)
      if (res.GetResult) {
        this.postData = JSON.parse(JSON.stringify(res.GetResult))
        this.postData.S_OP_Json = this._clJson(this.postData.S_OP_Json)
      }
    })


  },
  methods: {
    saves() {
      let ab = JSON.parse(JSON.stringify(this.postData))
      ab.S_OP_Json = this._jmjson(ab.S_OP_Json)
      this._http.post(this._getUrl('S_ALL_Normal_Save'), {
        "token": {
          "TimeSpan": 1566827733,
          "sToken": "768DCAFFF869CD61372790C2738012EC"
        },
        o: ab})
      .then(res => {
        // console.log(res)
        this.$alert(res.Msg,'提示')
      })
    }
  },
  components: {
    UPfile: () => import('@/components/CommonComponents/upFile'),

  }
}
</script>

<style scoped lang="scss">
.OCT {
  padding: 10vh 0;
  width: 90%;
  margin: 0 auto;
  text-align: left;
}
.bz {
  padding-top: 30px;
  p{
    padding-bottom: 10px;
  }
}
</style>
